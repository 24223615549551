<template>
  <div class="project-create">
    <div class="manage-wrapper">
    <span class="goBack" @click="goBack">返回</span>
      <div class="project-create-container">
        <iframe :src='$route.query.iframeUrl' width='100%' height='700' frameborder='0' style='overflow:auto'></iframe>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
    };
  },
  methods: {
    goBack(){
      this.$router.push('/home/manage/wjx')
    }
  },
  mounted() {
    console.log(this.$route.query.iframeUrl);
    this.$route.meta.title = this.$route.query.breadcrumbName
  }
};
</script>
  
<style lang="less" scoped>
  .goBack{
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
    color: #409EFF;
  }
</style>